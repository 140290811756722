<template>
  <form @submit.prevent="createProject()">
    <CytomineModal
      :active="active"
      :title="$t('study-create')"
      @close="$emit('update:active', false)"
    >
      <b-field
        :label="$t('name')"
        :type="{ 'is-danger': errors.has('name') }"
        :message="errors.first('name')"
      >
        <b-input v-model="name" v-validate="'required'" name="name" />
      </b-field>

      <b-field :label="$t('ontology')">
        <b-radio v-model="ontology" native-value="NO">
          {{ $t('no-ontology') }}
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="ontology" native-value="EXISTING">
          {{ $t('use-existing-ontology') }}
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="ontology" native-value="NEW">
          {{ $t('create-ontology-for-project') }}
        </b-radio>
      </b-field>

      <template v-if="ontology === 'EXISTING'">
        <b-field
          :type="{ 'is-danger': errors.has('ontology') }"
          :message="errors.first('ontology')"
        >
          <b-select
            v-model="selectedOntology"
            v-validate="'required'"
            :placeholder="$t('select-ontology')"
            size="is-small"
            name="ontology"
          >
            <option
              v-for="ontology in ontologies"
              :key="ontology.id"
              :value="ontology.id"
            >
              {{ ontology.name }}
            </option>
          </b-select>
        </b-field>
      </template>

      <div v-if="currentUser.admin" class="mb-4">
        <div class="label">
          {{ $t('user-pool') }}
          <v-popover class="ml-1">
            <i class="fas fa-info-circle" />
            <template #popover>
              <p>
                {{ $t('user-pool-explanation') }}
              </p>
            </template>
          </v-popover>
        </div>
        <BField>
          <DomainTagInput
            v-model="activeSelection"
            :domains="users"
            :allow-new="true"
            placeholder="Enter user or domain"
            displayed-property="email"
            group-displayed-property="fullName"
            searched-property="fullName"
            @input="addSelectedUserOrDomain"
          />
        </BField>
        <div v-for="record in selectedUsersAndDomains" :key="record">
          <span v-if="getUserByEmail(record)">
            <span style="font-weight: bold">User</span>:
            {{ getUserByEmail(record).email }}
          </span>
          <span v-else>
            <span style="font-weight: bold">Domain</span>: {{ record }}
          </span>
          <button
            style="background-color: transparent; cursor: pointer"
            @click="() => removeSelectedUserOrDomain(record)"
          >
            <BIcon pack="fas" icon="times-circle" class="color-primary" />
          </button>
        </div>
      </div>

      <template #footer>
        <button
          class="button"
          type="button"
          @click="$emit('update:active', false)"
        >
          {{ $t('cancel') }}
        </button>
        <button :disabled="errors.any()" class="button is-link">
          {{ $t('save') }}
        </button>
      </template>
    </CytomineModal>
  </form>
</template>

<script>
import { Project, Ontology } from 'cytomine-client';
import CytomineModal from '@/components/utils/CytomineModal.vue';
import DomainTagInput from '@/components/utils/DomainTagInput.vue';
import { fullName } from '@/utils/user-utils.js';
import noteApi from '@/services/noteApi.js';

export default {
  name: 'AddProjectModal',
  components: { CytomineModal, DomainTagInput },
  props: {
    active: Boolean,
    ontologies: Array,
  },
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      name: '',
      ontology: 'NO',
      selectedOntology: null,
      selectedUsersAndDomains: [],
      activeSelection: [],
      users: [],
    };
  },
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
  },
  watch: {
    async active(val) {
      if (val) {
        this.name = '';
        this.ontology = 'NO';
        this.selectedOntology = null;

        if (this.currentUser.admin) {
          const userData = await noteApi.get('/api/user.json');
          this.users = userData.collection.map((user) => {
            user.fullName = fullName(user);
            return user;
          });
        }
      }
    },
  },
  async created() {
    const userData = await noteApi.get('/api/user.json');
    this.users = userData.collection.map((user) => {
      user.fullName = fullName(user);
      return user;
    });
  },
  methods: {
    async createProject() {
      const result = await this.$validator.validateAll();
      if (!result) {
        return;
      }

      try {
        let idOntology;
        if (this.ontology === 'NEW') {
          const ontology = await new Ontology({ name: this.name }).save();
          idOntology = ontology.id;
        } else if (this.ontology === 'EXISTING') {
          idOntology = this.selectedOntology;
        }

        const project = await new Project({
          name: this.name,
          ontology: idOntology,
        }).save();

        this.$notify({
          type: 'success',
          text: this.$t('project-creation-notif-success'),
        });
        this.$router.push(`/project/${project.id}/configuration`);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('project-creation-notif-error'),
        });
      }
    },

    getUserByEmail(email) {
      return this.users.find((a) => a.email === email);
    },

    async addSelectedUserOrDomain(userOrDomain) {
      const isDomain = userOrDomain && userOrDomain[0].id ? false : true;
      let value = isDomain ? userOrDomain[0] : userOrDomain[0].email;
      if (isDomain && !value.startsWith('@')) {
        value = '@' + value;
      }
      this.selectedUsersAndDomains.push(value);
      this.activeSelection = [];
    },

    async removeSelectedUserOrDomain(userOrDomain) {
      this.selectedUsersAndDomains = this.selectedUsersAndDomains.filter(
        (a) => a !== userOrDomain
      );

      this.activeSelection = [];
    },
  },
};
</script>
